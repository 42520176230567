import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  useColorModeValue,
  Spacer,
  Image,
  Tooltip,
  Icon,
  Wrap,
  WrapItem,
  Button,
} from '@chakra-ui/react';
import {
  SiCsharp,
  SiDotNet,
  SiVueDotJs,
  SiDocker,
  SiElasticstack,
  SiMicrosoftsqlserver,
  SiAzurepipelines,
  SiAzuredevops,
  SiPython,
  SiNodeDotJs,
  SiReact,
  SiFlask,
  SiPostgresql,
  SiLaravel,
  SiJava,
  SiSwift,
  SiJest,
  SiRedis,
  SiTwilio,
  SiDigitalocean
} from "react-icons/si";
import { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useMediaQuery } from "@chakra-ui/react";

const ECCC = [SiCsharp,SiDotNet,SiVueDotJs,SiDocker,SiElasticstack,SiMicrosoftsqlserver,SiAzurepipelines,SiAzuredevops];
const McMaster = [SiPython,SiRedis,SiTwilio,SiDigitalocean,SiCsharp,SiDotNet];
const Icarus = [SiReact,SiPython,SiNodeDotJs,SiFlask,SiPostgresql,SiJest]
const Mohawk = [SiPython,SiCsharp,SiDotNet,SiJava,SiReact,SiVueDotJs,SiSwift,SiLaravel,SiNodeDotJs]

export default function Experience({Data}) {
  const [icons, setIcons] = useState(ECCC);
  const bg = useColorModeValue('#f7fafc', '#232323')
  const icarusSrc = useColorModeValue('/files/Icarus.png','/files/Icarus-light.png')
  const [isMobile] = useMediaQuery("(max-width: 768px)")
  const [isStandard] = useMediaQuery("(max-width: 2559px)");
  
  useEffect(()=>{
    setIcons(getIcons(Data.title));
  },[])

  function getIcons(title){
    switch(title){
      case "Environment & Climate Change Canada":
        return ECCC;
      case "McMaster University":
        return McMaster;
      case "Icarus Medical":
        return Icarus;
      case "Mohawk College":
        return Mohawk;
      default:
        return [];
    }
  }

  return (
    <Center>
      <Box
        maxW={isStandard ? '400px' : '445px'}
        w={'full'}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        overflow={'hidden'}
        bg={bg}>
        <Box h={isMobile ? Data.img.m : Data.img.b} pos={'relative'}>
          <Center>
            <Image mt={Data.img.t} htmlWidth={Data.img.w} htmlHeight={Data.img.h} src={Data.title === "Icarus Medical" ? icarusSrc : Data.img.src}/>
          </Center>
        </Box>
        <Stack>
          <Heading
            color={useColorModeValue('gray.700', 'white')}
            fontSize={isStandard ? 'sm' : 'md'}
            fontFamily={'body'}
            textAlign={'left'}>
            {Data.title}
          </Heading>
          <Heading
            color={useColorModeValue('gray.700', 'white')}
            fontSize={isStandard ? 'sm' : 'md'}
            fontFamily={'body'}
            textAlign={'left'}>
            {Data.detail}
          </Heading>
          <Heading
            color={useColorModeValue('gray.700', 'white')}
            fontSize={isStandard ? 'sm' : 'md'}
            fontFamily={'body'}
            textAlign={'left'}>
            {Data.dates}
          </Heading>
          <Box p={2} pt={isMobile ? 0 : 25} >
          <Center>
            <Wrap fontSize={'3xl'}>
              {Data.tools.map((tool, index) => (
                <WrapItem key={index}>
                <Tooltip label={tool.tooltip} fontSize="xl">
                  <span>
                    <Icon as={icons[index]} />
                  </span>
                </Tooltip>
              </WrapItem>
              ))}
            </Wrap>
          </Center>
          </Box>
          <Text fontFamily={'body'}  fontSize={isStandard ? 'sm' : 'md'} textAlign={'left'}>
            {Data.description}
          </Text>
          <HashLink smooth to={Data.projects} key={Data.projects}>        
              <Button  w={isMobile ? '150px' : '350px'}>
               Projects 
              </Button>
          </HashLink>
        </Stack>
      </Box>
      <Spacer />
    </Center>
  );
}